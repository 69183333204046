import React, { useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import { FaBars, FaTimes } from "react-icons/fa"; // Import icons

const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	return (
		<Nav>
			<Logo>Guillaume Ratier</Logo>
			<Hamburger onClick={toggleMenu}>{isOpen ? <FaTimes /> : <FaBars />}</Hamburger>
			<NavLinks isOpen={isOpen}>
				<Link to="home" smooth={true} duration={500} onClick={toggleMenu}>
					Home
				</Link>
				<Link to="education" smooth={true} duration={500} onClick={toggleMenu}>
					Education
				</Link>
				<Link to="experience" smooth={true} duration={500} onClick={toggleMenu}>
					Experience
				</Link>
				<Link to="projects" smooth={true} duration={500} onClick={toggleMenu}>
					Projects
				</Link>
				<Link to="languages" smooth={true} duration={500} onClick={toggleMenu}>
					Languages
				</Link>
			</NavLinks>
		</Nav>
	);
};

export default Navbar;

const Nav = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 40px;
	background-color: var(--navy);
	position: fixed;
	top: 0;
	width: 95%;
	z-index: 100;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

	@media (max-width: 768px) {
		width: 87%;
		padding: 20px 30px;
	}
`;

const Logo = styled.div`
	font-size: 24px;
	font-weight: bold;
	color: var(--green);
`;

const Hamburger = styled.div`
	display: none;
	font-size: 28px;
	color: var(--white);
	cursor: pointer;

	@media (max-width: 768px) {
		display: block;
	}
`;

const NavLinks = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	a {
		margin: 0 15px;
		font-size: 16px;
		font-weight: 500;
		color: var(--white);
		cursor: pointer;
		transition: color 0.3s ease;

		&:hover {
			color: var(--green);
		}
	}

	@media (max-width: 768px) {
		position: absolute;
		top: 60px;
		left: 0;
		width: 100%;
		flex-direction: column;
		background-color: var(--navy);
		padding: 20px 0;
		transition: all 0.3s ease-in-out;
		transform: ${({ isOpen }) => (isOpen ? "translateY(0)" : "translateY(-100%)")};
		opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
		visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};

		a {
			margin: 15px 0;
		}
	}
`;
