import React from "react";
import styled from "styled-components";
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";

const Home = () => {
	return (
		<Section id="home">
			<Image src="/main.png" alt="Guillaume Ratier" />
			<Intro>
				<h1>Hello, I'm Guillaume Ratier</h1>
				<p>Cybersecurity & Data Science student focusing on Machine Learning, Deep Learning, and LLMs.</p>
			</Intro>
			<SocialLinks>
				<a href="mailto:guillaume.ratier@outlook.fr" target="_blank" rel="noopener noreferrer">
					<FaEnvelope />
				</a>
				<a href="https://github.com/gratier" target="_blank" rel="noopener noreferrer">
					<FaGithub />
				</a>
				<a href="https://www.linkedin.com/in/guillaume-ratier-98b502147/" target="_blank" rel="noopener noreferrer">
					<FaLinkedin />
				</a>
				{/* Add more icons as needed */}
			</SocialLinks>
		</Section>
	);
};

export default Home;

const Section = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0px 20px;
	min-height: 100vh;
	text-align: center;
	background-color: var(--navy);
`;

const Image = styled.img`
	width: 180px;
	border-radius: 50%;
	margin-bottom: 20px;
	border: 4px solid var(--green);
`;

const Intro = styled.div`
	h1 {
		font-size: 36px;
		margin-bottom: 10px;
		color: var(--white);
	}

	p {
		font-size: 18px;
		max-width: 600px;
		margin: 0 auto;
		color: var(--slate);
	}
`;

const SocialLinks = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: center;
	gap: 20px;

	a {
		color: var(--light-slate);
		font-size: 28px;
		transition: color 0.3s ease;

		&:hover {
			color: var(--green);
		}
	}
`;
