import React from "react";
import styled from "styled-components";

const Experience = () => {
	return (
		<Section id="experience">
			<h2>Experience</h2>
			<Card>
				<Logo src="/odu.png" alt="Old Dominion University" />
				<Details>
					<h3>RCC Student Worker</h3>
					<p>Old Dominion University, Norfolk | Fall 2024-Present</p>
					<p>Student worker for the Research and Cloud Computing Department at ODU</p>
                    <p>&nbsp;&nbsp;&nbsp;- LLM Operator</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Building LLM inferences for researchers (Llama, Mistral, Stable Diffusion ...)</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Model performance comparisons for CCI-A projects</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Development of RAG agents for public use using LangChain and LangGraph</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Attending AI workshops and creating AI learning projects for interns</p>
                    <p>&nbsp;&nbsp;&nbsp;- CCI-A Student Worker</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Development in the CCI Academic virtual environment</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Troubleshooting issues with existing labs</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Project assistance in the CCI Research environment</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Documentation development in CCI-R</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Installation of specialized software</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Coordination of upgrades and patches</p>
                    <p>&nbsp;&nbsp;&nbsp;- HPC and Cloud Computing Student Worker</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Automation associated with the deployment of cybersecurity environments</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Interfacing with researchers on Cybersecurity academic projects</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Assist researchers in their use of the environment</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• Workstation and Data Center management in the Monarch Hall Cybersecurity space</p>
				</Details>
			</Card>
			<Card>
				<Logo src="/odu.png" alt="Old Dominion University" />
				<Details>
					<h3>Cloud and DevOps Intern</h3>
					<p>Old Dominion University, Norfolk | Summer 2024</p>
					<p>Intern at the Research and Cloud Computing Department of ODU</p>
                    <p>&nbsp;&nbsp;&nbsp;• LLM and RAG development using AWS Bedrock</p>
                    <p>&nbsp;&nbsp;&nbsp;• Developing documentation and tutorials for the HPC environment</p>
                    <p>&nbsp;&nbsp;&nbsp;• Developing virtual machines for the CCI-A virtual environment</p>
                    <p>&nbsp;&nbsp;&nbsp;• Developing Python scripts to automate cloud environment on AWS with Kion and Redhat</p>
				</Details>
			</Card>
			<Card>
				<Logo src="/mineminds.png" alt="MineMinds" />
				<Details>
					<h3>Founder</h3>
					<p>Denmark, Remote | 2020</p>
					<p>Management and Administrative work on Minecraft-related servers and communities with tens of thousands of users.</p>
					<p>CVR 41613459</p>
				</Details>
			</Card>
			{/* Add more experience entries as needed */}
		</Section>
	);
};

export default Experience;

const Section = styled.section`
	padding: 70px 20px;
	background-color: var(--navy);
`;

const Card = styled.div`
	display: flex;
	background-color: var(--lightest-navy);
	padding: 20px;
	margin-bottom: 20px;
	border-radius: 8px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
	width: 80px;
	height: 80px;
	margin-right: 20px;
`;

const Details = styled.div`
	h3 {
		margin-bottom: 5px;
		color: var(--green);
	}

	p {
		margin: 5px 0;
		color: var(--lightest-slate);
	}
`;
