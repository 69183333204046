import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Education from "./pages/Education";
import Experience from "./pages/Experience";
import Projects from "./pages/Projects";
import Languages from "./pages/Languages";
import "./styles/global.css";

function App() {
	return (
		<div>
			<Navbar />
			<Home />
			<Education />
			<Experience />
			<Projects />
			<Languages />
		</div>
	);
}

export default App;
