import React from "react";
import styled from "styled-components";

const Languages = () => {
	return (
		<Section id="languages">
			<h2>Languages</h2>
			<Card>
				<Logo src="/france.webp" alt="French" />
				<Details>
					<h3>French</h3>
					<p>Native</p>
				</Details>
			</Card>
			<Card>
				<Logo src="/usa.webp" alt="USA" />
				<Details>
					<h3>English</h3>
					<p>Native</p>
				</Details>
			</Card>
			<Card>
				<Logo src="/spain.webp" alt="Spain" />
				<Details>
					<h3>Spanish</h3>
					<p>Notions</p>
				</Details>
			</Card>
			<Card>
				<Logo src="/denmark.webp" alt="Denmark" />
				<Details>
					<h3>Danish</h3>
					<p>Notions</p>
				</Details>
			</Card>
		</Section>
	);
};

export default Languages;

const Section = styled.section`
	padding: 70px 20px;
	background-color: var(--navy);
`;

const Card = styled.div`
	display: flex;
	background-color: var(--lightest-navy);
	padding: 20px;
	margin-bottom: 20px;
	border-radius: 8px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
	width: 80px;
	height: 80px;
	margin-right: 20px;
	border-radius: 50%;
`;

const Details = styled.div`
	h3 {
		margin-bottom: 5px;
		color: var(--green);
	}

	p {
		margin: 5px 0;
		color: var(--lightest-slate);
	}
`;
